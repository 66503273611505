<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('labels.log_request_delivery') }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t('labels.close') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table fixed-header class="table-padding-2" height="calc(100vh - 250px)">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">{{ $t('labels.time') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.type') }}</th>
                <th role="columnheader" class="text-center" style="width: 40%">{{ $t('labels.request') }}</th>
                <th role="columnheader" class="text-center" style="width: 40%">{{ $t('labels.response') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id" style="vertical-align: top">
                <td class="text-center">{{ formatDateTime(item.created_at) }}</td>
                <td class="text-center">{{ item.action }}</td>
                <td>
                  <div style="white-space: pre-wrap">{{ JSON.stringify(JSON.parse(item.request_data), undefined, 2) }}
                  </div>
                </td>
                <td>
                  <div style="white-space: pre-wrap">{{ JSON.stringify(JSON.parse(item.response_data), undefined, 2) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LogOrderDeliveryRequest",
  props: {
    idOrder: {
      type: Number,
      default: () => 0
    }
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async getList() {
      const { data } = await httpClient.post('/order-online-get-delivery-request-log', { id_order: this.idOrder })
      this.items = [...data]
    }
  }
}
</script>

<style scoped></style>
